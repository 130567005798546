<template>
  <div>
    <div>
      <el-form :inline="true">
        <el-form-item label="售后状态">
          <el-select v-model="status" placeholder="请选择" clearable>
            <el-option
              v-for="item in StatusList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="售后类型">
          <el-select v-model="type" placeholder="请选择" clearable>
            <el-option label="课程退课" value="退课"> </el-option>
            <el-option label="换课" value="换课"> </el-option>
            <el-option label="优惠券退款" value="coupon_refund"> </el-option>
            <el-option label="消费金退款" value="consume_refund"> </el-option>
            <el-option label="场馆服务退款" value="venue_refund"> </el-option>
            <el-option label="需求保证金退款" value="need_bond_refund"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <auto-table :DataList="list" :option="option" @changePage="changePage">
      <el-table-column label="类型">
        <template slot-scope="{ row }">
          <p>{{ saleAfter_type[row.type] || row.type }}</p>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="{ row }">
          <p v-if="row.status == 1">已取消</p>
          <p v-if="row.status == 2">售后中</p>
          <p v-if="row.status == 3">已同意</p>
          <p v-if="row.status == 4">已拒绝</p>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-button type="primary" size="small" @click="toHandle(row)"
            >处理</el-button
          >
        </template>
      </el-table-column>
    </auto-table>

    <!--  -->
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      list: [],
      currentPage: 1,
      total: 0,
      dialogVisible: false,
      changeDialogVisible: false,
      formInline: {},
      order: {},
      status: "2",
      type: "",
      saleAfter_type: {
        退课: "退课",
        换课: "换课",
        coupon_refund: "优惠券退款",
        consume_refund: "消费金退款",
        venue_refund: "服务退款",
         need_bond_refund:'需求保证金退款'
      },
      StatusList: [
        {
          value: "2",
          name: "售后中",
        },
        {
          value: "3",
          name: "已同意",
        },
        {
          value: "4",
          name: "已拒绝",
        },
      ],
      option: [
        {
          name: "可退金额",
          value: "amount",
        },
        {
          name: "可退权益点",
          value: "point",
        },
        {
          name: "时间",
          value: "create_time",
        },
        {
          name: "理由",
          value: "reason",
        },
      ],
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    toHandle(row) {
      this.$router.push({
        name: "refundDetails",
        query: { id: row.id, oid: row.recharge_record_id },
      });
    },
    search() {
      this.currentPage = 1;
      this.getList();
    },
    changePage(v) {
      this.currentPage = v;
      this.getList();
    },
    getList() {
      let params = {
        currentPage: this.currentPage,
        status: this.status,
        type: this.type || null,
        pageSize: 10,
      };
      this.$axios({
        url: "/user/rechargerecordrefund/queryManagerListPage",
        params,
      }).then((res) => {
        this.list = res.data.data.rows;
        this.total = res.data.data.total;
      });
    },
    handleClick() {},
    currentPageChange(v) {
      this.currentPage = v;
      this.getList();
    },
  },
};
</script>
  
  <style lang="scss" scoped>
</style>