<template>
  <div class="container">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>
  
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="售后申请" name="couser">
        <courseRefund></courseRefund>
      </el-tab-pane>
      <el-tab-pane label="违约补偿申请" name="compensate">
        <compensateRecord></compensateRecord>
      </el-tab-pane>
    
    </el-tabs>
  </div>
</template>

<script>
import compensateRecord from './components/compensateRecord.vue';
import courseRefund from "./components/courseRefund.vue";

export default {
  components: {
    courseRefund,
    compensateRecord
  },
  data() {
    return {
      activeName: 'couser',
      nav: { firstNav: "订单管理中心", secondNav: "售后审核" },
      tableData: [],
      currentPage:1,
      total:0
    };
  },
  created() {
  
  },
  
  methods: {
 
    handleClick() {

    },
  },
};
</script>

<style lang="less">
#Refundaudit {
  padding: 0 20px;
  background-color: #ffffff;
}
.cell {
  text-align: center;
}
.el-tabs__item {
  font-size: 16px !important;
}
</style>
