<template>
  <div class="">
    <el-form :inline="true">
      <el-form-item label="类型">
        <el-select v-model="type" placeholder="选择类型" clearable>
          <el-option label="用户申请" value="user_apply"></el-option>
          <el-option label="教练申请" value="coach_apply"></el-option>
        </el-select>
        
      </el-form-item>
       <el-form-item label="状态">
        <el-select v-model="status" placeholder="选择状态" clearable>
          <el-option label="待处理" :value="0"></el-option>
          <el-option label="已处理" :value="1"></el-option>
          <el-option label="已取消" :value="2"></el-option>
        </el-select>
        
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #type="{ row }">
        <div>
          {{ row.type == "user_apply" ? "用户申请" : "教练申请" }}
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag v-if="row.status === 0" type="info">待处理</el-tag>
          <el-tag v-if="row.status === 1" type="success">已处理</el-tag>
          <el-tag v-if="row.status === 2" type="primary">已取消</el-tag>
        </div>
      </template>
      <template #result="{ row }">
        <div>
          {{ row.handler_result == "none" ? "不补偿" : "补偿申请人" }}
        </div>
      </template>
       <template #handle="{ row }">
        <div>
          <el-button
            type="primary"
            @click="openHandler(row)"
            >处理</el-button
          >
        </div>
      </template>
    </auto-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "补偿金额", value: "amount" },
        { name: "类型", value: "type", type: "custom" },
        { name: "原因", value: "remark" },
        { name: "状态", value: "status", type: "custom" },
         { name: "处理结果", value: "result" ,type: "custom"},
        { name: "提交时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom" },
      ],
      Total: 0,
      type: '',
      status:''
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    openHandler(row) {
      this.$router.push({ name: "compensateDetails", query: { id: row.id } });
    },
    //分成记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/compensateRecord/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          type:this.type || null,
          status:this.status || null
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>