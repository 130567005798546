var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"类型"}},[_c('el-select',{attrs:{"placeholder":"选择类型","clearable":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('el-option',{attrs:{"label":"用户申请","value":"user_apply"}}),_c('el-option',{attrs:{"label":"教练申请","value":"coach_apply"}})],1)],1),_c('el-form-item',{attrs:{"label":"状态"}},[_c('el-select',{attrs:{"placeholder":"选择状态","clearable":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('el-option',{attrs:{"label":"待处理","value":0}}),_c('el-option',{attrs:{"label":"已处理","value":1}}),_c('el-option',{attrs:{"label":"已取消","value":2}})],1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("搜索")])],1)],1),_c('auto-table',{attrs:{"DataList":_vm.DataList,"option":_vm.Option,"total":_vm.Total},on:{"changePage":_vm.getList},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.type == "user_apply" ? "用户申请" : "教练申请")+" ")])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',[(row.status === 0)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("待处理")]):_vm._e(),(row.status === 1)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("已处理")]):_vm._e(),(row.status === 2)?_c('el-tag',{attrs:{"type":"primary"}},[_vm._v("已取消")]):_vm._e()],1)]}},{key:"result",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.handler_result == "none" ? "不补偿" : "补偿申请人")+" ")])]}},{key:"handle",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.openHandler(row)}}},[_vm._v("处理")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }